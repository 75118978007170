import React from 'react';

const ResetPassword = () => {
  return (
    <div>
      <h1>ResetPassword</h1>
    </div>
  );
};

export default ResetPassword;
